@import "./Options";

#root {
  background: linear-gradient(#fff, #f7f7f7) !important;
}

body {
  min-width: 300px;
}

.responsive-text-size {
  font-size: .9rem;
  @media (min-width: $breakpoint-phone) {
    font-size: .95rem;
  }
  @media (min-width: $breakpoint-tablet) {
    font-size: 1rem;
  }
  @media (min-width: $breakpoint-big-tablet) {
    font-size: 1.05rem;
  }
  @media (min-width: $breakpoint-desktop) {
    font-size: 1.1rem;
  }
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.page-content-container {
  padding: 3rem 0;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #e8e8e8;

  .inner-page-content {
    width: 100%;
    min-width: 300px;
    margin: 0 auto;

    .inner-page-content-title {
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: .75rem;

      strong {
        font-weight: 600;
      }
    }
  }

  &:last-of-type {
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
  }
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.clickable {
  cursor: pointer;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.nav-link-button {
  font-size: inherit;
  font-weight: inherit;
  text-transform: unset;
}

// Wave
.Ripple.is-reppling {
  animation: ripple 0.5s ease-in-out;
}
@keyframes ripple {
  0% { transform: scale(0); opacity: 0}
  5% { opacity: 0 }
  20% { opacity: .75 }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.project-video {
  opacity: 1;
  transition: opacity .5s ease-in-out;
  background-color: #0e0e10;
  &.hidden {
    opacity: 0;
  }

  &:not(.show-video-controls) {
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
  }
}

.project-video-controls {
  position: absolute;
  bottom: 0;
  padding: .75rem 1.75rem;
  left: 0;
  right: 0;

  .video-control-option {
    @extend .clickable;

    width: 2rem;
    opacity: .75;
    margin-right: 1.25rem;

    fill: rgba(255, 255, 255, 1);

    -webkit-filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, .25));
    filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, .25));
  }
}

.info-table {
  &.table th,
  &.table td {
    padding-top: .6rem;
    padding-bottom: .5rem;
  }
  tr:first-of-type td {
    padding-top: 1.5rem !important;
  }
  tr td:first-of-type {
    width: 40%;
    @media (min-width: $breakpoint-big-tablet) {
      width: 50%;
    }
    @media (min-width: $breakpoint-desktop) {
      width: 40%;
    }
  }
}
