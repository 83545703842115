@import "../Options";

#landing-page {
  height: 95vh;
  background-color: #f5f5f5;
  padding: 0 !important;
  margin: 0 !important;

  .inner-page-content-container {
    background: rgba(245, 245, 245, .8);
    position: absolute;
    top: 35%;
    width: 100%
  }
  @supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
    .inner-page-content-container {
      background-color: rgba(245, 245, 245, .4);
      -webkit-backdrop-filter: blur(.25rem);
      backdrop-filter: blur(.25rem);
    }
  }

  .inner-page-content {
    $width: 275px;
    width: $width;
    max-width: initial;
    margin: 0 calc(50% - #{($width / 2)});

    position: relative;
    min-height: 7.5rem;

    h5 {
      font-size: .8rem;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    @media (min-width: $breakpoint-phone) {
      $width: 385px;
      width: $width;
      margin: 0 calc(50% - #{($width / 2)});

      h5 {
        font-size: .8rem;
        margin-top: .5rem;
        position: relative;
      }
    }
    @media (min-width: $breakpoint-tablet) {
      $width: 570px;
      width: $width;
      margin: 0 calc(50% - #{($width / 2)});

      h5 {
        font-size: 1rem;
        margin-top: 1rem;
      }
    }
    @media (min-width: $breakpoint-big-tablet) {
      $width: 600px;
      width: $width;
      margin: 0 calc(50% - #{($width / 2)});
    }
    @media (min-width: $breakpoint-desktop) {

    }

    #landing-page-profile-picture {
      float: left;
      margin-right: .8rem;

      width: 85px;

      @media (min-width: $breakpoint-phone) {
        margin-right: 1.1rem;
        width: 100px;
      }
      @media (min-width: $breakpoint-tablet) {
        margin-right: 1.5rem;
        width: 140px;
      }
      @media (min-width: $breakpoint-big-tablet) {
        margin-right: 2rem;
        width: 165px;
      }
      @media (min-width: $breakpoint-desktop) {

      }

      img {
        width: 100%;
        border-radius: 50% 3% 50% 50%;
      }
    }

    #landing-page-name {
      font-size: 1.5rem;
      font-weight: 600;

      @media (min-width: $breakpoint-phone) {
        font-size: 2rem;
      }
      @media (min-width: $breakpoint-tablet) {
        font-size: 2.5rem;
      }
      @media (min-width: $breakpoint-big-tablet) {
        font-size: 3rem;
      }
      @media (min-width: $breakpoint-desktop) {

      }
    }
    #landing-page-title {
      font-size: 1rem;

      @media (min-width: $breakpoint-phone) {
        font-size: 1.5rem;
      }
      @media (min-width: $breakpoint-tablet) {
        font-size: 1.8rem;
      }
      @media (min-width: $breakpoint-big-tablet) {
        font-size: 2rem;
      }
      @media (min-width: $breakpoint-desktop) {

      }
    }
  }

  .scroll-indicator {
    position: absolute;
    top: calc(95vh - 25px);
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid rgba(214, 214, 214, 0.75);
    border-radius: 50px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.75);
    transition: opacity .2s, background-color .2s;
    -webkit-animation: scroll-show-animation 1s 5s;
    -webkit-animation-fill-mode: forwards;
    animation: scroll-show-animation 1s 5s;
    animation-fill-mode: forwards;
    visibility: hidden;
    opacity: 0;

    &:before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: '';
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: rgba(214, 214, 214, 0.75);
      border-radius: 100%;
      -webkit-animation: sdb10 2s infinite;
      animation: sdb10 2s infinite;
      box-sizing: border-box;
    }

    &:hover {
      opacity: 1 !important;
      background-color: rgba(255, 255, 255, 0.95);
    }

    @-webkit-keyframes scroll-show-animation {
      0% {
        visibility: visible;
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: .85;
      }
    }
    @keyframes scroll-show-animation {
      0% {
        visibility: visible;
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: .85;
      }
    }

    @-webkit-keyframes sdb10 {
      0% {
        -webkit-transform: translate(0, 0);
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        -webkit-transform: translate(0, 17px);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes sdb10 {
      0% {
        transform: translate(0, 0);
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        transform: translate(0, 17px);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
}