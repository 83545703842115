@import "../Options";

footer {
  background-color: #2c2525;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.75);
}

.social-buttons-text-large {
  display: none;
  @media (min-width: $breakpoint-big-tablet) {
    display:block;
  }
}
.social-buttons-small-round {
  display:block;
  @media (min-width: $breakpoint-big-tablet) {
    display:none;
  }
}

.btn-floating {
  box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
  width: 47px;
  height: 47px;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  transition: all .15s cubic-bezier(.5,.2,.25,1);
  margin: 10px;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: white;
    margin-left: 1px;
  }
}
.btn-floating.btn-lg {
  width: 61.1px;
  height: 61.1px;
}
.btn-floating i {
  display: inline-block;
  width: inherit;
  text-align: center;
  color: #fff;
}
.btn-floating i {
  font-size: 1.25rem !important;
  line-height: 47px;
}
.btn-floating.btn-lg i {
  font-size: 1.625rem !important;
  line-height: 61.1px;
}

.floating-fixed-br {
  position: fixed !important;
  bottom: 1.25rem;
  right: 1.25rem;
  &.hidden{
    transform: scale(0.5);
    opacity: 0;
  }
}

.btn-li {
  background-color: #0082ca !important;
  color: #fff;
  &:hover {
    background-color: #0092e4 !important;
  }
  &.active, &:active, &:focus {
    background-color: #004064 !important;
  }
  &.focus, &:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
  }
  &:not([disabled]):not(.disabled).active, &:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #004064 !important;
  }
}
.btn-git {
  background-color: #333 !important;
  color: #fff;
  &:hover {
    background-color: #404040 !important;
  }
  &.active, &:active, &:focus {
    background-color: #000 !important;
  }
  &.focus, &:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
  }
  &:not([disabled]):not(.disabled).active, &:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #000 !important;
  }
}
.btn-so {
  background-color: #ffac44 !important;
  color: #fff;
  &:hover {
    background-color: #ffb75e !important;
  }
  &.active, &:active, &:focus {
    background-color: #dd7b00 !important;
  }
  &.focus, &:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
  }
  &:not([disabled]):not(.disabled).active, &:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #dd7b00 !important;
  }
}
.btn-steam {
  background-color: #282828 !important;
  color: #fff;
  &:hover {
    background-color: #353535 !important;
  }
  &.active, &:active, &:focus {
    background-color: #000 !important;
  }
  &.focus, &:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
  }
  &:not([disabled]):not(.disabled).active, &:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #000 !important;
  }
}
.btn-email {
  background-color: #4b515d !important;
  color: #fff;
  &:hover {
    background-color: #565d6b !important;
  }
  &.active, &:active, &:focus {
    background-color: #1d2025 !important;
  }
  &.focus, &:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
  }
  &:not([disabled]):not(.disabled).active, &:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #1d2025 !important;
  }
}